<template>
  <NuxtLink
    :to="to"
    :replace="route.fullPath.includes('sports')"
    class="text-xs py-2 inline-block transition-colors duration-100 text-slate-400 hover:text-white leading-none"
  >
    <slot/>
  </NuxtLink>
</template>

<script setup>
defineProps({
  to: {
    type: String,
    required: true,
  },
});

const route = useRoute();
</script>
