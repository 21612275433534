import { Log } from '@/core/logger';

export const useTimeoutRetry = async(asyncFn, timeout, maxRetries) => {
  let retries = 0;

  while (retries <= maxRetries) {
    try {
      return await Promise.race([
        asyncFn(),
        new Promise((_resolve, reject) => setTimeout(() => reject(new Error('Timeout')), timeout)),
      ]);
    } catch (error) {
      if (error.message === 'Timeout') {
        Log.error(`Attempt ${retries + 1} timed out. Retrying...`);
        retries++;
      } else {
        throw error; // Throw the error if it's not a timeout
      }
    }
  }

  throw new Error(`Function timed out after ${maxRetries} attempts.`);
};
