import validate from "/home/circleci/project/projects/main/node_modules/nuxt/dist/pages/runtime/validate.js";
import _1_45security_45global from "/home/circleci/project/projects/main/middleware/1.security.global.js";
import _2_45redirects_45global from "/home/circleci/project/projects/main/middleware/2.redirects.global.js";
import page_45load_45global from "/home/circleci/project/projects/main/middleware/pageLoad.global.js";
import username_45global from "/home/circleci/project/projects/main/middleware/username.global.js";
import manifest_45route_45rule from "/home/circleci/project/projects/main/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _1_45security_45global,
  _2_45redirects_45global,
  page_45load_45global,
  username_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "route-guard-auth": () => import("/home/circleci/project/projects/main/middleware/routeGuardAuth.js"),
  "route-guard-games": () => import("/home/circleci/project/projects/main/middleware/routeGuardGames.js"),
  "route-guard-signup": () => import("/home/circleci/project/projects/main/middleware/routeGuardSignup.js"),
  "sports-betting": () => import("/home/circleci/project/projects/main/middleware/sportsBetting.js")
}