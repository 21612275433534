import { defineStore } from 'pinia';
import { useStorage, useDebounceFn } from '@vueuse/core';

import { useBankingStore } from '@/store/banking';
import { useDeviceStore } from '@/store/device';
import { useLocationStore } from '@/store/location';

export const useUiStore = defineStore({
  id: 'ui-store',
  state: () => {
    return {
      storeInitialized: false,
      chatChannel: 'lobby',
      chatState: 'hidden', // fullscreen, minified, hidden
      navState: 'hidden', // full, mini, hidden
      isMobileNavOpen: false,
      isMobileNavHidden: false,
      isChatOpen: false,
      showDepositModal: false,
      depositModalVariant: 'normal', // or 'insufficientBalance' or 'depositToPlay'
      showWalletModal: false,
      showRedeemModal: false,
      showIntroductionModal: false,
      walletModalFundsContext: 'Default', // or 'Custodial',
      showRaffleEntryModal: false,
      showPageLoader: true,
      showRaffleWinModal: false,
      showConfirmModal: false,
      showUserDetailsModal: false,
      confirmModalData: null,
      showErrorModal: false,
      errorModalType: null, // 'General', 'InsufficientWalletBalance', 'LoginFailed', 'MinigameNotFound', 'RestrictedLocation'
      errorModalTitle: null,
      errorModalMessage: null,
      errorModalData: null,
      showReloadModal: false,
      showWhyConnectModal: false,
      showTournamentEnterModal: false,
      tournamentEnterModalData: null,
      showInfoModal: false,
      infoModalType: null, // 'Welcome', 'WithdrawalConfirmation', 'CompetitionReminder', 'Cms'
      infoModalContent: null,
      infoModalWidthMode: null,
      showUserFlags: useStorage('showUserFlags', null),
      infoModalStats: null,
      showMediaModal: false,
      mediaModalData: null,
      showConnectModal: false,
      showVolatilityModal: false,
      connectNoLogin: false,
      connectFromEmail: false,
      connectWeb3Only: false,
      connectSwap: false,
      connectMessage: null,
      connectChainType: null,
      showLoginEmailModal: false,
      showLoginEmailOneTimeLinkModal: false,
      showInfoRegisterModal: false,
      showNetworkModal: false,
      openWalletAfterRegister: true,
      infoRegisterModalEmailOnly: false,
      showSignatureModal: false,
      showWalletConfirmModal: false,
      showMethModal: false,
      reminderModalHide: useStorage('reminderModalHide', false),
      welcomeModalHide: useStorage('welcomeModalHide3', false),
      welcomePromoModalHide: useStorage('welcomePromo150ethModalHide4', false),
      secondsTimerSub: null,
      secondsTicker: false,
      balanceInPlay: false,
      walletModalData: null,
      uiSettings: {
        videoAutoplay: useStorage('uiSettingsVideoAutoplay2', false),
        prizeValueCurrency: useStorage('uiSettingsPrizeValueCurrency', 'USD'),
      },
      allowModalsOnPageLoad: false,
      toastNotification: null, // { type: "waiting", content: "Some html content", action: { type: 'link', destination: '/wallet' },  closeAfter: 5000 },
      toastNotificationPresets: {
        walletConnected: { type: 'success', title: 'Wallet Connected', action: null, closeAfter: 3000, delayed: false, },
      },
      discordChatEnabled: false,
      showGameModeSwitchModal: false,
      showAnnouncementModal: true,
      messageRotatorHidden: false,
      showDepositAccountModal: false,
      addAliasType: null,
      addAliasStep: 1,
      addAliasDest: null,
      showAddAliasModal: false,
      redirecting: false,
      nftPurchaseModalSuccessDetails: null,
      gamesRestrictedModalDismissed: false,
      hiddenBalances: useStorage('hiddenBalances', {}),
      hideZeroBalances: useStorage('hideZeroBalances', false),
      showBridgeModal: false,
      bridgeModalConfig: null,
      showPromoModal: false,
      promoModalData: null,
      claimItem: null,
      gamesMenuItems: [],
      showChat: true,
      web3LoginProvider: null,
      disableChangeBalance: false,
      activeGameProviderRef: null,
      replayModalUrl: null,
      showTradingHowModal: false,
      tradingHowModalData: null,
      showTradingRoiModal: false,
      showTradingOrderFormModal: false,
      tradingOrderFormModalData: null,
      showTradingOrderDetailModal: false,
      tradingOrderDetailModalData: null,
      showTradingVerifiablyFairModal: false,
      tradingVerifiablyFairModalData: null,
      showTradingLicenseModal: false,
      txModalInfo: {
        mode: 'Deposit', // mode = walletModalModeTypes = 'Deposit' | 'Withdraw';
        subMode: 'web3', // subMode = walletModalSubModeTypes= 'web3' | 'custodial' | 'cards';
        currency: 'ETH',
        depositCode: null,
      },
      casinoNavIsCollapsed: true,
      sportsNavIsCollapsed: true,
      userNavIsCollapsed: true,
      communityNavIsCollapsed: true,
    };
  },
  getters: {
    templateClass() {
      if (this.navState === 'full' && this.chatState === 'fullscreen') {
        return 'nav-open-chat-open';
      } else if (this.navState === 'full') {
        return 'nav-open';
      } else if (this.chatState === 'fullscreen') {
        return 'chat-open';
      }

      return 'chat-open';
    },
  },
  actions: {
    async init() {
      if (this.storeInitialized) { return; }
      this.allowModalsOnPageLoad = false;
      this.startSecondsTicker();
      window.addEventListener('resize', useDebounceFn(this.resizeHandler, 200));
      // Initial resize is called from the layouts/default.vue as it must be triggered after the layout including ClientOnly is rendered

      // Enable Discord chat on all browsers except MetaMaskMobile on Android
      const deviceStore = useDeviceStore();
      this.discordChatEnabled = !((deviceStore.isMetaMaskMobile && deviceStore.isAndroidDevice));
      await useLocationStore().init();
      const { restricted, country, region, restrictedFeatures: features, } = useLocationStore();
      const nuxtApp = useNuxtApp();

      // Check the API to see if the location is restricted, if it is -> disable platform engagement
      if (nuxtApp.$config.public.RESTRICT_LOCATIONS && restricted && !features?.length) {
        setTimeout(() => {
          this.showErrorModal = true;
          this.errorModalType = 'LocationRestricted';
          this.errorModalData = { country, region, };
        }, 300);
      }

      this.storeInitialized = true;
    },
    setNavState(newState) {
      if (newState) {
        this.navState = newState;
        return;
      }

      const windowWidth = window.innerWidth;
      if (windowWidth < 1024) {
        if (this.isMobileNavOpen) {
          this.navState = 'full';
        }
        this.navState = 'hidden';
      } else if (windowWidth < 1280) {
        this.isMobileNavOpen = false;
        this.navState = 'mini';
      } else {
        this.isMobileNavOpen = false;
        this.navState = 'full';
      }
    },
    toggleChatState() {
      if (['hidden',].includes(this.chatState)) {
        this.chatState = 'fullscreen';
      } else if (['fullscreen', 'minified',].includes(this.chatState)) {
        this.chatState = 'hidden';
      }
    },
    startSecondsTicker() {
      if (!this.secondsTimerSub) {
        this.secondsTimerSub = setInterval(() => {
          this.secondsTicker = !this.secondsTicker;
        }, 1000);
      }
    },
    closeMobileNav(removeScroll = true) {
      this.isMobileNavOpen = false;
      if (window.innerWidth < 1024) {
        if (removeScroll) {
          useBodyScroll(removeScroll);
        }
        this.navState = 'hidden';
      }
    },
    openInfoCmsModal(content) {
      this.infoModalType = 'Cms';
      this.infoModalContent = content;
      this.showInfoModal = true;
    },
    /**
     * mode = walletModalModeTypes = 'Deposit' | 'Withdraw';
     * subMode = walletModalSubModeTypes= 'web3' | 'custodial' | 'cards';
     */
    openTxModal(mode = 'Deposit', subMode = 'web3', currency = undefined, depositCode = undefined) {
      const bankingStore = useBankingStore();

      this.txModalInfo.mode = mode;
      this.txModalInfo.subMode = subMode;
      this.txModalInfo.currency = currency || this.walletModalData?.currencyCode || bankingStore.currentCurrency;
      this.txModalInfo.depositCode = depositCode;

      this.showWalletModal = !this.showWalletModal;
      setTimeout(() => {
        this.closeMobileNav(false);
      }, 200);
    },
    openRegisterInfoModal() {
      this.showInfoRegisterModal = true;
    },
    openAddAliasModal(type, step = 1, dest = null) {
      this.addAliasType = type;
      this.addAliasStep = step;
      this.addAliasDest = dest;
      this.showAddAliasModal = true;
      this.closeConnectModal();
    },
    closeAddAliasModal() {
      this.addAliasType = null;
      this.addAliasDest = null;
      this.showAddAliasModal = false;
    },
    openConnectModal(options) {
      this.connectMessage = options?.message || null;
      this.connectNoLogin = options?.noLogin || false;
      this.connectFromEmail = options?.fromEmail || false;
      this.connectWeb3Only = options?.web3Only || false;
      this.connectSwap = options?.swap || false;
      this.connectChainType = options?.chainType || null;
      this.showConnectModal = true;
    },
    openBridgeModal(config) {
      this.bridgeModalConfig = config;
      this.showBridgeModal = true;
    },
    closeBridgeModal() {
      this.showBridgeModal = false;
      this.bridgeModalConfig = null;
    },
    closeConnectModal() {
      this.showConnectModal = false;
      this.connectMessage = null;
      this.connectNoLogin = false;
      this.connectWeb3Only = false;
      this.connectSwap = false;
      this.connectChainType = null;
    },
    openPromoModal(data) {
      this.showPromoModal = true;
      this.promoModalData = data;
    },
    closePromoModal() {
      this.showPromoModal = false;
      this.promoModalData = null;
    },
    closeModals() {
      this.showDepositModal = false;
      this.showRaffleEntryModal = false;
      this.showPageLoader = false;
      this.showRaffleWinModal = false;
      this.showErrorModal = false;
      this.errorModalData = null;
      this.closeConnectModal();
      this.showLoginEmailModal = false;
      this.showLoginEmailOneTimeLinkModal = false;
      this.showNetworkModal = false;
      this.showSignatureModal = false;
      this.showWalletConfirmModal = false;
      this.showMediaModal = false;
      this.showWalletModal = false;
      this.showConnectModal = false;
      this.showDepositAccountModal = false;
      this.showAddAliasModal = false;
      this.closeAddAliasModal();
      this.closeBridgeModal();
      // exception for WithdrawalConfirmation modal
      if (this.showInfoModal && this.infoModalType !== 'WithdrawalConfirmation') {
        this.showInfoModal = false;
      }
    },
    softReset() {
      this.closeModals();
      this.isMobileNavOpen = false;
      this.depositModalVariant = 'normal';
      this.errorModalType = null;
      this.welcomeModalHide = false;
      this.errorModalMessage = null;
    },
    hashAction(hashString) {
      if (this.showMediaModal) { return; }
      const params = hashString.substring(hashString.indexOf('#') + 1).split('&');
      const data = {};
      for (let i = 0; i < params.length; i++) {
        data[params[i].split('=')[0]] = params[i].split('=')[1];
      }
      if (data?.ui === 'modal' && data?.media === 'yt' && data?.id) {
        this.mediaModalData = data;
        this.showMediaModal = true;
      }
    },
    closeConfirmModal() {
      this.showConfirmModal = false;
      this.confirmModalData = null;
    },
    resizeHandler() {
      const windowHeight = window.innerHeight;
      const navHeight = document.querySelector('.js-nav-height-calc')?.clientHeight || document.querySelector('.nav-games')?.clientHeight || 60;
      document.documentElement.style.setProperty('--windowHeight', `${windowHeight}px`);
      document.documentElement.style.setProperty('--navHeight', `${navHeight}px`);
      this.setNavState();
    },
    /**
     * NB: Not currently used
     */
    openDepositAccountModal() {
      this.showDepositAccountModal = true;
      setTimeout(() => {
        this.closeMobileNav();
      }, 200);
    },
    showTxToastNotification(action, txType) {
      const txDesc = txType;

      switch (action) {
        case 'RequireConfirm':
          this.toastNotification = {
            type: 'waiting',
            title: `Pending ${txDesc}`,
            content: `Check your email to confirm your ${txDesc.toLowerCase()} request`,
            action: null,
            closeAfter: 5000,
            delayed: true,
          };
          break;

        case 'Pending':
        case 'Confirmed':
          this.toastNotification = {
            type: 'waiting',
            title: `Pending ${txDesc}`,
            content: 'Awaiting Confirmation',
            action: null,
            closeAfter: 5000,
            delayed: true,
          };
          break;

        case 'Complete':
          this.toastNotification = {
            type: 'success',
            title: `${txDesc} Complete`,
            action: null,
            closeAfter: 5000,
            delayed: false,
          };
          break;

        case 'Error':
          this.toastNotification = {
            type: 'info',
            title: `${txDesc} Failed`,
            action: null,
            closeAfter: 5000,
            delayed: false,
          };
          break;

        default:
          throw new Error(`Unsupported TX toast action '${action}'`);
      }
    },
    updateNftPurchaseModalSuccessDetails(tierId, network, isNFTOnly = false, success = true, reset = false) {
      if (reset) {
        this.nftPurchaseModalSuccessDetails = null;
      } else {
        this.nftPurchaseModalSuccessDetails = {
          tierId,
          network,
          isNFTOnly,
          success,
        };
      }
    },
  },
});
