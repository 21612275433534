// Docs: https://www.npmjs.com/package/@gtm-support/vue-gtm
import { createGtm } from '@gtm-support/vue-gtm';
import { useAuthStore } from '@/store/auth';

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter();
  const authStore = useAuthStore();

  function gtmUserId() {
    if (authStore?.user?.username) {
      return authStore?.user?.username;
    } else {
      window.dataLayer.push({
        user_id: undefined,
      });
      return false;
    }
  }

  const gtm = createGtm({
    id: nuxtApp.$config.public.GTM_ID,
    defer: true,
    compatibility: false,
    enabled: true,
    debug: false,
    loadScript: true,
    vueRouter: router,
    vueRouterAdditionalEventData: () => ({
      ...(gtmUserId() && { user_id: gtmUserId(), }),
    }),
    trackOnNextTick: false,
  });

  nuxtApp.vueApp.use(gtm);
});
