import { defineStore } from 'pinia';

export const useSportsBettingStore = defineStore('sportsBettingStore', () => {
  const redirectToPath = ref('/');
  const sportsBettingProviders = ref(null);
  const selectedSportsBettingProvider = ref(null);
  const triggeredFromNav = ref(false);
  const previousSubPath = ref(null);

  function setSelectedSportsBettingProvider(name) {
    selectedSportsBettingProvider.value = sportsBettingProviders.value.filter(provider => provider.name.toLowerCase() === name.toLowerCase())[0];
  }

  /**
   * NB: Can't currently refactor out `useNuxtApp()`
   * as it doesn't know it exists at this point.
   */
  async function fetchSportsBettingProviders() {
    try {
      const response = await useNuxtApp().$api('/sport/provider/', {
        method: 'GET',
      });
      sportsBettingProviders.value = response;
    } catch (err) {
      useNuxtApp().$rollbar.error('fetchSportsBettingProviders failed:::', err);
      console.log('fetchSportsBettingProviders failed:::', err);
    }
  }

  function $reset() {
    redirectToPath.value = '/';
    selectedSportsBettingProvider.value = null;
  }

  return {
    redirectToPath,
    selectedSportsBettingProvider,
    triggeredFromNav,
    previousSubPath,
    sportsBettingProviders,

    fetchSportsBettingProviders,

    setSelectedSportsBettingProvider,

    $reset,
  };
});
