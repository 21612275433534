import { gte } from 'semver';
import BigNumber from 'bignumber.js';

export default defineNuxtPlugin(() => ({
  provide: {
    getNFTTokenNumber(name, tokenId) {
      if (!name) { return null; }
      if (!name.includes('#')) { return tokenId; }

      const parts = name.match(/#([0-9]+)/g);

      if (!parts || parts.length === 0) { return tokenId; }

      return parts[0].slice(1);
    },
    getNFTName(name, collectionName) {
      if (name?.includes('#')) {
        return collectionName || name?.split('#')?.[0];
      }

      return name;
    },

    semverGte(v1, v2) {
      if (!v1 || !v2) { return false; }
      return !!gte(v1, v2);
    },

    sumProps(items, prop) {
      return items.reduce((a, b) => {
        return a + Number(b[prop]);
      }, 0);
    },

    preventLetterInput(e) {
      const keyCode = e.which;
      if (keyCode === 46) {
        return true;
      }
      if ((keyCode !== 8 || keyCode === 32) && (keyCode < 48 || keyCode > 57)) {
        e.preventDefault();
      }
    },

    getArraysDifference(arr1, arr2, key) {
      return arr1.filter((obj1) => {
        return !arr2.some((obj2) => {
          return obj1[key] === obj2[key];
        });
      });
    },

    round(input, decimals) {
      return new BigNumber(input).decimalPlaces(decimals).toNumber();
    },

    isElementVisible(el) {
      const rect = el?.getBoundingClientRect();
      const vWidth = window.innerWidth || document.documentElement.clientWidth;
      const vHeight = window.innerHeight || document.documentElement.clientHeight;
      const efp = function(x, y) {
        return document.elementFromPoint(x, y);
      };

      // Return false if it's not in the viewport
      if (rect.right < 0 || rect.bottom < 0 || rect.left > vWidth || rect.top > vHeight) { return false; }

      // Return true if any of its four corners are visible
      return (
        el.contains(efp(rect.left, rect.top))
        || el.contains(efp(rect.right, rect.top))
        || el.contains(efp(rect.right, rect.bottom))
        || el.contains(efp(rect.left, rect.bottom))
      );
    },

    moveElementInArray(array, moveIndex, toIndex) {
      const item = array[moveIndex];
      const length = array.length;
      const diff = moveIndex - toIndex;

      if (diff > 0) {
        // move left
        return [
          ...array.slice(0, toIndex),
          item,
          ...array.slice(toIndex, moveIndex),
          ...array.slice(moveIndex + 1, length),
        ];
      } else if (diff < 0) {
        // move right
        return [
          ...array.slice(0, moveIndex),
          ...array.slice(moveIndex + 1, toIndex + 1),
          item,
          ...array.slice(toIndex + 1, length),
        ];
      }
      return array;
    },

    convertExponentialNumber(num) {
      const newNumber = new BigNumber(num);
      return newNumber.toFixed();
    },

    isEqualIgnoreCase(s1, s2) {
      return s1.toUpperCase() === s2.toUpperCase();
    },
  },
}));
