import { useGtm } from '@gtm-support/vue-gtm';
import { useAuthStore } from '@/store/auth';

export default defineNuxtPlugin(() => {
  const authStore = useAuthStore();

  return {
    provide: {
      gtmCustomEvent: (params) => {
        const gtm = useGtm();
        if (authStore?.isUserLogged && !params.user_id) {
          params.user_id = authStore?.user?.username;
        } else {
          window.dataLayer.push({
            user_id: undefined,
          });
        }

        return gtm.trackEvent(params);
      },
    },
  };
});
