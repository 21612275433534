import { MetaMaskProvider } from './providers/metamask';
import { WalletConnectProvider } from './providers/wallet-connect';
import { PROVIDER_NAME, mapProviderName } from './mapProviderName';
import { CoinbaseProvider } from './providers/coinbase';
import { PhantomProvider } from './providers/phantom';

export function createWalletProvider(name) {
  switch (mapProviderName(name)) {
    case PROVIDER_NAME.MetaMask:
      return new MetaMaskProvider();
    case PROVIDER_NAME.WalletConnect:
      return new WalletConnectProvider();
    case PROVIDER_NAME.Coinbase:
      return new CoinbaseProvider();
    case PROVIDER_NAME.Phantom:
      return new PhantomProvider();
    default:
      throw new Error(`Wallet provider '${name}' not supported.`);
  }
}
