<template>
  <transition
    :name="transitionNameComp"
    @after-leave="onAfterLeave"
  >
    <div
      v-if="showing"
      class="fixed inset-0 w-full h-screencalc flex max-h-screen overflow-y-auto py-3 sm:px-3"
      :class="{
        'cursor-pointer': !hideClose && !bgCloseDisable,
        'z-60': priority,
        'z-50': !priority,
        'px-2' : infoModalType === 'WelcomePromo150eth',
        'pb-0 sm:pb-3' : transitionNameComp === 'modal-slide-up',
        'bg-slate-500/90': !bgTransparent
      }"
      @click.self.prevent="bgClose"
    >
    <button
          v-if="!hideClose && noPadding"
          aria-label="close"
          class="absolute z-10 flex justify-center items-center top-2 right-2 w-8 h-8 bg-slate-600 rounded-full transition-all hover:bg-slate-500 animate-slide-down-fade-in07s"
          :class="{
            'opacity-50': dimClose
          }"
          tabindex="0"
          @click.prevent="closeModal"
        >
          <span class="icon-ico-x text-xs"/>
        </button>
      <div
        class="relative w-full shadow-2xl rounded-xl m-auto cursor-auto"
        :class="modalClass"
      >
        <button
          v-if="!hideClose && !noPadding"
          aria-label="close"
          class="absolute z-10 flex justify-center items-center top-2 right-2 w-8 h-8 rounded-full transition-all animate-slide-down-fade-in07s"
          :class="{
            'opacity-50': dimClose,
            'bg-slate-600 hover:bg-slate-500' : !noPadding
          }"
          tabindex="0"
          @click.prevent="closeModal"
        >
          <span class="icon-ico-x text-xs"/>
        </button>
        <slot/>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'ModalMain',
  props: {
    mediaType: {
      type: String,
      default: null,
    },
    modalWidthMode: {
      type: String,
      default: null,
    },
    priority: {
      type: Boolean,
      default: false,
    },
    allowModalGoBack: {
      type: Boolean,
      default: false,
    },
    hideClose: {
      type: Boolean,
      default: false,
    },
    infoModalType: {
      type: String,
      default: null,
    },
    transitionName: {
      type: String,
      default: null,
    },
    dimClose: {
      type: Boolean,
      default: false,
    },
    bgCloseDisable: {
      type: Boolean,
      default: false,
    },
    bgTransparent: {
      type: Boolean,
      default: false,
    },
    noPadding: {
      type: Boolean,
      deafult: false,
    },
  },
  emits: [
    'close',
    'modal-go-back',
  ],
  data() {
    return {
      showing: false,
    };
  },
  computed: {
    widthClass() {
      switch (this.modalWidthMode) {
        case 'lg':
        case 'xl':
        case '2xl':
        case '3xl':
          return `max-w-${this.modalWidthMode}`;
        case 'full':
          return '';
        default:
          return 'max-w-md';
      }
    },
    modalClass() {
      const baseClass = [this.widthClass,];

      const modalType = this.infoModalType === 'WelcomePromo150eth' ? 'border-2 border-[#EBBC38]' : '';
      const transitionNameComp = this.transitionNameComp === 'modal-slide-up' ? 'mt-auto mb-0 rounded-b-none nested-container sm:m-auto sm:rounded-xl sm:animate-slide-down-fade-in04s' : 'animate-slide-down-fade-in04s';
      let padding = '';

      if (this.mediaType === 'video' && !this.noPadding) {
        padding = 'pt-8 max-w-7xl';
      } else if (this.mediaType !== 'video' && !this.noPadding) {
        padding = 'py-8 bg-slate-900';
      } else if (this.transitionNameComp === 'modal-slide-up') {
        padding = 'pt-2 pb-4';
      } else {
        padding = 'py-0';
      }

      return [baseClass, modalType, transitionNameComp, padding,];
    },
    transitionNameComp() {
      return this.transitionName || 'modal';
    },
  },
  mounted() {
    this.showing = true;
    useBodyScroll(false);
    document.addEventListener('keydown', this.onEscape);
  },
  beforeUnmount() {
    document.removeEventListener('keydown', this.onEscape);
    useBodyScroll(true);
  },
  methods: {
    bgClose() {
      if (this.bgCloseDisable) { return; }
      return this.closeModal();
    },
    closeModal() {
      useBodyScroll(true);

      if (this.hideClose) { return; }
      if (this.allowModalGoBack) {
        return this.$emit('modal-go-back');
      }
      this.showing = false;
      this.$emit('close');
    },
    onAfterLeave() {
      if (this.hideClose) { return; }
      this.$emit('close');
    },
    onEscape(e) {
      if (this.showing && e.keyCode === 27) {
        this.onAfterLeave();
      }
    },
  },
};
</script>
