import { defineStore } from 'pinia';
import { useLocalStorage } from '@vueuse/core';
import { useWebsocketStore } from '@/store/websocket';

export const useTradingStore = defineStore('trading-store', () => {
  const DEFAULT_PREFERENCES = {
    chart: {
      showUserBets: true,
      showPublicBets: true,
    },
    form: {},
  };

  const LICENSE_COOKIE_OPTIONS = {
    expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 30), // 30 Days
  };

  const assets = ref(new Map());
  const assetsLoaded = ref(false);
  const initiated = ref(false);
  const preferences = useLocalStorage('tradingPreferences', DEFAULT_PREFERENCES);
  const license = useCookie('tradingLicense', LICENSE_COOKIE_OPTIONS);
  const activeOrderTab = ref(null);
  const priceInterval = ref('Tick');
  const enabled = ref(useNuxtApp().$config.public.TRADING_ENABLED);
  const openOrders = ref([]);

  async function init() {
    initiated.value = true;

    if (!enabled.value) {
      return;
    }

    await loadAssets();

    useWebsocketStore().$onAction(({ name, args, }) => {
      if (name !== 'handleMessage' || !['Trading:Price', 'Trading:Asset:Changed',].includes(args[0].type)) {
        return;
      }

      const type = args[0].type;
      const payload = args[0].payload;

      if (type === 'Trading:Asset:Changed') {
        const route = useRoute();
        loadAssets();

        if (!route.path.includes(`trading/${payload.code.toLowerCase()}`)) {
          return;
        }

        const router = useRouter();

        if (!payload.public || !payload.enabled) {
          router.push('/');
        }
      } else if (type === 'Trading:Price') {
        const code = payload.code.toUpperCase();
        const asset = assets.value.get(code);

        if (!asset) {
          return;
        }

        assets.value.set(code, {
          ...asset,
          ...payload,
        });
      }
    });

    const route = useRoute();
    const router = useRouter();
    const isTradingPath = /trading/g.test(route.path);

    if (isTradingPath && !route.params.asset && this.assets.size > 0) {
      const [code,] = [...assets.value,][0];
      router.push(`/trading/${code.toLowerCase()}`);
    }
  }

  async function loadAssets() {
    try {
      const response = await useNuxtApp().$api('/trading/asset/', {
        method: 'GET',
      });

      const sorted = response.sort((a, b) => a.sortOrder - b.sortOrder);
      const mapped = sorted.map(asset => ([asset.code.toUpperCase(), asset,]));
      assets.value = new Map(mapped);
      assetsLoaded.value = true;
    } catch (err) {
      useNuxtApp().$rollbar.error('method failed', err);
    }
  }

  function findAsset(code, type) {
    if (!code) {
      return;
    }

    const asset = assets.value.get(code.toUpperCase());

    if (type && type !== asset.type) {
      return;
    }

    return asset;
  }

  async function fetchPrices(code, interval, count) {
    try {
      const response = await useNuxtApp().$api(`/trading/asset/${code}/price/${interval}`, {
        method: 'GET',
        query: {
          count,
        },
      });
      return response.reverse();
    } catch (err) {
      useNuxtApp().$rollbar.error('method failed', err);
    }
  }

  async function fetchOrders(assetCode, active, skip, take) {
    try {
      const response = await useNuxtApp().$api('/trading/order/', {
        method: 'GET',
        query: {
          assetCode,
          active,
          skip,
          take,
        },
      });
      return response;
    } catch (err) {
      nuxtApp.$rollbar.error('method failed', err);
    }
  }

  async function fetchCurrentRound(code) {
    try {
      const response = await useNuxtApp().$api(`/trading/asset/${code}/round/current`, {
        method: 'GET',
      });
      return response;
    } catch (err) {
      useNuxtApp().$rollbar.error('method failed', err);
    }
  }

  async function fetchRoundHistory(code, count) {
    try {
      const response = await useNuxtApp().$api(`/trading/asset/${code}/round`, {
        method: 'GET',
        query: {
          count,
        },
      });
      return response;
    } catch (err) {
      useNuxtApp().$rollbar.error('method failed', err);
    }
  }

  async function addOrder(order) {
    try {
      const response = await useNuxtApp().$api('/trading/order/', {
        method: 'POST',
        body: order,
      });
      return response;
    } catch (err) {
      useNuxtApp().$rollbar.error('method failed', err);
      throw err;
    }
  }

  async function closeOrder(id, assetCode) {
    try {
      const response = await useNuxtApp().$api('/trading/order/close', {
        method: 'PUT',
        body: {
          orderId: id,
          assetCode,
        },
      });
      return response;
    } catch (err) {
      useNuxtApp().$rollbar.error('method failed', err);
      throw err;
    }
  }

  return {
    assets,
    assetsLoaded,
    initiated,
    preferences,
    license,
    openOrders,
    activeOrderTab,
    priceInterval,
    enabled,
    init,
    loadAssets,
    findAsset,
    fetchPrices,
    fetchOrders,
    fetchCurrentRound,
    fetchRoundHistory,
    addOrder,
    closeOrder,
  };
});
