/*
Custom plugin to change image src to use a specified width in resource request depending on original source and provided size.
Use in simple img tags without any art-direction.
*/
export default defineNuxtPlugin(() => {
  return {
    provide: {
      imgSrcResize: (url, width, height) => {
        if (!url) { return '/img/placeholders/image-not-found.png'; }
        if (!width) { width = 500; }
        let urlUniversal = '';

        if (url.startsWith('https://img.seadn.io') || url.startsWith('https://i.seadn.io')) {
          // Options: https://docs.imgix.com/apis/rendering/format/fm
          urlUniversal = url.split('?')[0] + `?w=${width}&auto=format`;
        } else if (url.startsWith('https://lh3.googleusercontent.com')) {
          // Potentially working options: https://developers.google.com/people/image-sizing
          urlUniversal = url.split('=')[0] + `=s${width}`;
          if (height === width) { urlUniversal = urlUniversal + '-c'; }
        } else {
          urlUniversal = url;
        }

        return urlUniversal;
      },
    },
  };
});
