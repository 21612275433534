export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1, maximum-scale=1"},{"hid":"google-site-verification","name":"google-site-verification","content":"Fg84YBGjKy4o8CFDgDF8fTcDmlrKuk4Te0hmXL8kTwg"}],"link":[{"rel":"icon","type":"image/x-icon","href":"/meta/favicon.ico"},{"rel":"icon","type":"image/png","sizes":"32x32","href":"/meta/icon-192.png"},{"rel":"icon","type":"image/svg+xml","href":"/meta/icon.svg"},{"rel":"apple-touch-icon","sizes":"180x180","href":"/meta/apple-touch-icon.png"},{"rel":"shortcut icon","href":"/meta/favicon.ico"},{"rel":"manifest","href":"/meta/site.webmanifest"},{"rel":"preconnect","href":"https://www.gstatic.com","crossorigin":"anonymous"},{"rel":"preconnect","href":"https://connect.facebook.net","crossorigin":"anonymous"},{"rel":"preconnect","href":"https://www.google.com","crossorigin":"anonymous"},{"rel":"preconnect","href":"https://www.googletagmanager.com","crossorigin":"anonymous"}],"style":[],"script":[{"src":"https://cdn.jsdelivr.net/npm/@widgetbot/html-embed","async":true,"defer":true},{"src":"https://static.moonpay.com/web-sdk/v1/moonpay-web-sdk.min.js","defer":true}],"noscript":[],"bodyAttrs":{"class":"w-full bg-slate-600 text-white"},"htmlAttrs":{"lang":"en"}}

export const appBaseUrl = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnUrl = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = false

export const appManifest = true

export const remoteComponentIslands = false

export const devPagesDir = null

export const devRootDir = null

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'