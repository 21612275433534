import { defineStore } from 'pinia';
import { useUiStore } from '@/store/ui';
import { useMinigamesStore } from '@/store/minigames';

export const useVersionStore = defineStore({
  id: 'version-store',
  state: () => {
    return {
      versionControl: null,
      versionTimerSub: null,
      pageLoaderDebounce: null,
    };
  },
  actions: {
    init() {
      const uiStore = useUiStore();

      // Check every 30 sec
      if (!this.versionTimerSub) {
        this.versionTimerSub = setInterval(() => {
          this.checkVersion('timer');
        }, 30000);
      }

      // Check when tab visiblity changes (in focus)
      this.handleVisibilityChange();

      // Check when the loading spinner is visible for more than 10s
      uiStore.$subscribe((mutation, state) => {
        if (state.showPageLoader) {
          this.pageLoaderDebounce = setTimeout(() => {
            this.checkVersion('showPageLoaderStateOver10s');
            this.pageLoaderDebounce = null;
          }, 10000);
        } else {
          if (this.pageLoaderDebounce) { clearTimeout(this.pageLoaderDebounce); }
          this.pageLoaderDebounce = null;
        }
      });
    },
    handleVisibilityChange() {
      document.addEventListener('visibilitychange', () => {
        if (document.visibilityState === 'visible') {
          this.checkVersion('visibilityChange');
        }
      });
    },
    async checkVersion(trigger = 'other') {
      const uiStore = useUiStore();
      const minigamesStore = useMinigamesStore();
      const route = useRoute();

      // Exit if reload modal is already open
      if (uiStore.showReloadModal) { return; }

      try {
        const buildVersionFile = await $fetch(`/meta/build.json?ts=${Date.now()}`, {
          headers: {
            'Cache-Control': 'no-cache',
          },
        });
        if (!buildVersionFile?.BUILD_DATE) {
          return;
        }

        const newVersion = JSON.stringify(buildVersionFile);
        if (this.versionControl && this.versionControl !== newVersion) {
          // Exit if other store states or pages don't allow for reload modal display
          if (minigamesStore.showGameContainer || route?.meta?.blockReloadModal) { return; }

          uiStore.showReloadModal = true;
          uiStore.showPageLoader = false;
        } else {
          this.versionControl = newVersion;
        }
      } catch (err) {
        const errMsg = `Error fetching build.json. Online: ${navigator.onLine}. Trigger: ${trigger}. Modal displayed: ${uiStore.showReloadModal}.`;
        useNuxtApp().$rollbar.warning(errMsg, err);
      }
    },
  },
});
