import { defineStore } from 'pinia';
import { Log } from '@/core/logger';
import { DEFAULT_WALLET_PROVIDER_NAME } from '@/core/wallet/mapProviderName';
import { createWalletProvider } from '@/core/wallet/createWalletProvider';

export const useWalletProviderStore = defineStore({
  id: 'wallet-provider-store',
  state: () => {
    return {
      name: DEFAULT_WALLET_PROVIDER_NAME,
      wallet: null,
      initiated: false,
      cacheKey: 'walletProvider',
    };
  },
  actions: {
    init() {
      Log.log('💰 Wallet Provider Init...');
      this.initiated = true;
      const providerName = localStorage.getItem(this.cacheKey) ?? DEFAULT_WALLET_PROVIDER_NAME;
      return this.set(providerName);
    },
    set(providerName) {
      if (this.wallet && this.name === providerName) {
        return this.wallet;
      }

      this.name = providerName;
      this.wallet = createWalletProvider(providerName);
      localStorage.setItem(this.cacheKey, providerName);

      Log.log('💰 Setting Wallet Provider...', this.wallet.name);

      return this.wallet;
    },
    async disconnect() {
      Log.log('💰 Wallet Provider Disconnected...');
      await this.wallet?.disconnect();
      localStorage.removeItem(this.cacheKey);
      this.$reset();
    },
  },
});
