export function getConfig(key) {
  const envVars = useRuntimeConfig().public;
  return envVars[key];
}

export function getConfigSplit(key, seperator) {
  const value = getConfig(key);

  if (!value) { return undefined; }

  seperator = seperator ?? ',';
  return value.split(seperator) ?? [];
}
