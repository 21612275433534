import { defineStore } from 'pinia';

export const useCompetitionStore = defineStore({
  id: 'competition-store',
  state: () => {
    return {
      showEnterPasswordModal: false,
      enterPasswordModalMetadata: null,
      storedPasswords: [],
    };
  },
  getters: {
  },
  actions: {
    getStoredPasswords() {
      const getCompetitionPasswords = localStorage.getItem('CompetitionAuthorisation');

      if (getCompetitionPasswords && getCompetitionPasswords.length > 0) {
        this.storedPasswords = JSON.parse(getCompetitionPasswords);
      }
    },
    updateStoredPasswords() {
      const competitionIndex = this.storedPasswords.findIndex(comp =>
        comp.sweepStakeId === this.enterPasswordModalMetadata.sweepStakeId
        && comp.packageId === this.enterPasswordModalMetadata.packageId);

      if (competitionIndex < 0) {
        this.storedPasswords.push({
          packageId: this.enterPasswordModalMetadata.packageId,
          password: this.enterPasswordModalMetadata.password,
          hasBeenUsed: true,
          sweepStakeId: this.enterPasswordModalMetadata.sweepStakeId,
        });

        localStorage.setItem('CompetitionAuthorisation', JSON.stringify(this.storedPasswords));
      }
    },
  },
});
