import { getAbi } from './getAbi';

function getContract(web3, sweepstake) {
  if (!sweepstake.blockchain?.contract) {
    useNuxtApp().$rollbar.error('getContract::: sweepstake.blockchain?.contract failed');
    return false;
  }
  const { address, abiId, } = sweepstake.blockchain.contract;
  return createContract(web3, address, abiId);
}

async function createContract(web3, address, abiId) {
  const abi = await getAbi(abiId);
  return new web3.eth.Contract(abi, address);
}

export { getContract, createContract, };
