import { coerce } from 'semver';

const DEFAULT_CONTRACT_VERSION = '1.0.0';

function getVersion(sweepstake) {
  const contract = sweepstake.blockchain?.contract;
  const version = getMetadata(sweepstake.metadata)
    ?? contract.version
    ?? DEFAULT_CONTRACT_VERSION;

  return parseVersion(version);
}

function getMetadata(metadata) {
  if (!metadata || !metadata.contractVersion) { return undefined; }

  return metadata.contractVersion;
}

function parseVersion(version) {
  const semver = coerce(version ?? DEFAULT_CONTRACT_VERSION);

  if (!semver) { throw new Error(`Unable to parse sweepstake contract version ${version}`); }

  return semver.version;
}

export { getVersion, parseVersion, DEFAULT_CONTRACT_VERSION, };
