import { storeToRefs } from 'pinia';

import { useUserStore } from '@/store/user';
import { useAuthStore } from '@/store/auth';
import { useLocationStore } from '@/store/location';

export function useExponea() {
  const userStore = useUserStore();
  const { userData, } = storeToRefs(userStore);

  const authStore = useAuthStore();
  const { user, wallet, authType, } = storeToRefs(authStore);

  const locationStore = useLocationStore();
  const { country, } = storeToRefs(locationStore);

  const { $dayjs, $config, } = useNuxtApp();

  const isFeatureFlagged = ref(['Dev', 'QA', 'Prod',].includes($config.public.ENV_NAME));

  function exponeaIdentify() {
    const exponeaParams = getConnectionParam();

    exponea.identify(
      {
        customer_id: userData.value.secureId,
      },
      {
        ...exponeaParams,
        country: country.value,
        timestamp: $dayjs(),
      }
    );

    exponea.track('login', {
      ...exponeaParams,
      country: country.value,
      timestamp: $dayjs(),
    });
  }

  function getConnectionParam() {
    const params = {
      Social: {
        connection: user.value?.attributes?.email,
        connection_value: user.value?.attributes?.['custom:external_provider'],
      },
      Web3: {
        connection: wallet.value?.address,
        connection_value: wallet.value?.name,
      },
      PasswordlessEmail: {
        connection: user.value?.attributes?.email,
        connection_value: 'Email',
      },
    };

    return params[authType.value] || {};
  }

  return {
    isFeatureFlagged,
    exponeaIdentify,
  };
}
