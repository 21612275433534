/*
Global error handling with Rollbar
*/
export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook('app:mounted', () => {
    window.onerror = function(err) {
      // Capture error caused by user clicking between the pages faster than a page transition finishes, reload page as it becomes unresponsive
      if (err.includes('The node before which the new node is to be inserted is not a child of this node')) {
        nuxtApp.$rollbar.warning('Error handled by auto reloading the page.', err);
        return window.location.reload();
      }
      return nuxtApp.$rollbar.error(err);
    };
  });

  nuxtApp.vueApp.config.errorHandler = (err) => {
    nuxtApp.$rollbar.error(err);
  };
});
