import { Vue3Mq } from 'vue3-mq';

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(Vue3Mq, {
    defaultBreakpoint: 'xxxs',
    breakpoints: {
      xxxxs: '0px',
      xxxs: '375px',
      xxs: '395px',
      xs: '414px',
      ssm: '510px',
      sm: '640px',
      md: '768px',
      lg: '1024px',
      xl: '1280px',
      xxl: '1536px',
      xxxl: '1700px',
      xxxxl: '1930px',
      xxxxxl: '2120px',
    },
  });
});
