async function getAbi(id) {
  const nuxtApp = useNuxtApp();
  try {
    const data = await nuxtApp.$api(`/sweepstake/abi/${id}`, {
      method: 'GET',
    });

    const { client, } = data;
    if (!client) { nuxtApp.$rollbar.error(`client abi is not available for ${id}`); }

    return JSON.parse(client);
  } catch (err) {
    nuxtApp.$rollbar.error('method failed', err);
  }
}

export { getAbi, };
