export const SWEEPSTAKE_CONTRACT_FEATURE = {
  MaxEntriesPerUser: 'MaxEntriesPerUser',
  RefundClaim: 'RefundClaim',
  NFTRestriction: 'NFTRestriction',
};

export const ALL_SWEEPSTAKE_CONTRACT_FEATURES = [
  SWEEPSTAKE_CONTRACT_FEATURE.MaxEntriesPerUser,
  SWEEPSTAKE_CONTRACT_FEATURE.NFTRestriction,
  SWEEPSTAKE_CONTRACT_FEATURE.RefundClaim,
];
