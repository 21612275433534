import { lt } from 'semver';
import { getConfig } from '@/utils/getConfig';

// TODO: Remove once types are unified across contracts
export function getBrand(version) {
  const brand = getConfig('BRAND_ID');

  if (lt(version, '3.0.0')) {
    return brand;
  }

  const mapping = {
    metawin: 0,
    metawinus: 1,
  };

  return mapping[brand] || 0;
}
