import { defineStore } from 'pinia';

export const useDeviceStore = defineStore({
  id: 'device-store',
  state: () => {
    return {
      initiated: false,
      isAndroidDevice: false,
      isMobileDevice: false,
      isMetaMaskMobile: false,
      browser: null,
    };
  },
  actions: {
    testAndroidDevice() {
      return /Android/i.test(navigator.userAgent);
    },
    testMobileDevice() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },
    testMetaMaskMobile() {
      return /MetaMaskMobile/i.test(navigator.userAgent);
    },
    testBrowser() {
      const ua = navigator.userAgent;
      let tem;
      let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
      if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return 'IE ' + (tem[1] || '');
      }
      if (M[1] === 'Chrome') {
        tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
        if (tem != null) { return tem.slice(1).join(' ').replace('OPR', 'Opera'); }
      }
      M = M[2] ? [M[1], M[2],] : [navigator.appName, navigator.appVersion, '-?',];
      if ((tem = ua.match(/version\/(\d+)/i)) != null) { M.splice(1, 1, tem[1]); }
      return M.join(' ');
    },
    init() {
      if (this.initiated) { return; }
      this.isAndroidDevice = this.testAndroidDevice();
      this.isMobileDevice = this.testMobileDevice();
      this.isMetaMaskMobile = this.testMetaMaskMobile();
      this.browser = this.testBrowser();
      this.initiated = true;
    },
  },
});
