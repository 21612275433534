import { defineStore } from 'pinia';
import Web3 from 'web3';

import { Log } from '~/core/logger';

export const useBlockchainConfigStore = defineStore({
  id: 'config-store',
  state: () => {
    return {
      initialised: false,
      configs: shallowRef({}),
    };
  },
  actions: {
    async init() {
      try {
        this.configs = await useNuxtApp().$api('/blockchain/config', {
          method: 'GET',
        });
        this.initialised = true;
      } catch (err) {
        Log.error(err);
      }
    },
    getExplorerUrl(network, type, value) {
      if (!this.configs[network]) {
        return null;
      }

      const config = this.configs[network];

      if (!config?.explorer) {
        return null;
      }

      const explorer = config.explorer;
      const url = new URL(explorer.url);
      let path = '';

      switch (type.toLowerCase()) {
        case 'address':
        case 'addr':
          path = explorer.paths?.address || 'address';
          break;

        case 'tx':
        case 'transaction':
          path = explorer.paths?.transaction || 'tx';
          break;
        default:
          return null;
      }

      if (path) { url.pathname = `${path}/${value}`; }

      if (explorer.query) {
        for (const key in explorer.query) {
          url.searchParams.set(key, explorer.query[key]);
        }
      }

      return url.toString();
    },
    getNetworkName(network) {
      const config = this.configs[network];

      if (!config) {
        return null;
      }

      return this.configs[network].name;
    },
    getNetworkChainConfig(network) {
      const config = this.configs[network];

      if (!config) {
        return null;
      }

      return {
        chainName: config.name,
        chainId: config.chainId,
        rpcUrls: config.rpcUrl ? [config.rpcUrl,] : undefined,
        iconUrls: config.iconUrl ? [config.iconUrl,] : undefined,
        blockExplorerUrls: config.explorer ? [config.explorer.url,] : undefined,
        nativeCurrency: config.nativeCurrency,
      };
    },
    getNetworkFromId(id) {
      if (!id) { return null; }

      id = typeof id === 'number' ? Web3.utils.numberToHex(id) : id;

      for (const network in this.configs) {
        if (this.configs[network].chainId?.toLowerCase() === id.toLowerCase()) {
          return network;
        }
      }

      return null;
    },
    getAllChainIds() {
      return Object.keys(this.configs)
      .map(network => this.configs[network].chainId)
      .filter(chainId => chainId);
    },
  },
});
