// Buttons style
export const useButtonStyle = (theme, size, ignoreDisabledStyle, padding, hasDisableColor = false) => {
  const getBtnDisabledClass = () => {
    if (ignoreDisabledStyle) {
      return '';
    }
    if (theme === 'gold') {
      // Theme: gold
      return hasDisableColor ? 'disabled:before:bg-black/30 disabled:before:opacity-100 disabled:before:mix-blend-normal ' : '';
    } else if (theme === 'primary-outline') {
      // Theme: primary-outline
      return hasDisableColor ? 'disabled:bg-slate-800 disabled:hover:bg-slate-800 shadow-none ' : '';
    } else if (theme === 'whatsapp') {
      // Theme: whatsapp
      return hasDisableColor ? 'disabled:opacity-40 disabled:hover:bg-green-700 ' : '';
    } else if (theme === 'discord') {
      // Theme: discord
      return hasDisableColor ? 'disabled:opacity-40 disabled:hover:bg-indigo-600 ' : '';
    } else if (theme === 'success') {
      // Theme: success
      return hasDisableColor ? 'disabled:opacity-40 disabled:hover:bg-green-700 ' : '';
    } else if (theme === 'grey' || theme === 'grey-light') {
      // Theme: grey
      return hasDisableColor ? 'disabled:opacity-40 disabled:hover:bg-slate-900 ' : '';
    } else if (theme === 'grey-dark') {
      // Theme: grey-dark
      return hasDisableColor ? 'disabled:opacity-40 disabled:hover:bg-black ' : '';
    } else if (theme === 'twitter') {
      // Theme: twitter
      return hasDisableColor ? 'disabled:opacity-40 disabled:hover:bg-[#1D9BF0] ' : '';
    } else if (theme === 'x') {
      // Theme: x
      return hasDisableColor ? 'disabled:opacity-40 disabled:hover:bg-white ' : '';
    } else if (theme === 'loadmore') {
      // Theme: loadmore
      return hasDisableColor ? 'disabled:opacity-100 ' : '';
    } else if (theme === 'facebook') {
      // Theme: facebook
      return hasDisableColor ? 'disabled:bg-blue-700 hover:disabled:bg-blue-700 ' : '';
    } else if (theme === 'google') {
      // Theme: google
      return hasDisableColor ? 'disabled:bg-gray-200 hover:disabled:bg-gray-200 ' : '';
    } else if (theme === 'link') {
      // Theme: link
      return hasDisableColor ? 'disabled:text-blue-400 hover:disabled:text-blue-400 ' : '';
    } else {
      // Theme: primary (default)
      return hasDisableColor ? 'disabled:opacity-40 disabled:hover:bg-blue-500 ' : '';
    }
  };

  const getBtnIdleClass = () => {
    if (theme === 'gold') {
      // Theme: gold
      return 'relative overflow-hidden bg-pure-gold-gradient font-bold leading-tight text-black rounded-md transition-all ease-out before:absolute before:w-full before:h-full before:bg-white/30 before:left-0 before:top-0 before:opacity-0 before:transition-all hover:before:opacity-100 before:mix-blend-overlay ';
    } else if (theme === 'primary-outline') {
      // Theme: primary-outline
      return 'bg-slate-600 font-bold leading-tight border-2 border-cyan-500 text-white rounded-md hover:bg-slate-800 hover:border-cyan-200 transition-all focus:bg-slate-800 focus:border-cyan-200 relative shadow-[0_0_2px_0] shadow-cyan-500 ';
    } else if (theme === 'whatsapp') {
      // Theme: whatsapp
      return 'bg-green-600 font-bold leading-tight text-white rounded-md hover:bg-green-700 transition-all focus:bg-green-700 relative ';
    } else if (theme === 'discord') {
      // Theme: discord
      return 'bg-indigo-500 font-bold leading-tight text-white rounded-md hover:bg-indigo-600 transition-all focus:bg-indigo-600 relative ';
    } else if (theme === 'success') {
      // Theme: success
      return 'bg-green-600 font-bold leading-tight text-white rounded-md hover:bg-green-700 transition-all focus:bg-green-700 relative ';
    } else if (theme === 'loadmore') {
      // Theme: loadmore
      return 'bg-none leading-tight text-white hover:opacity-90 transition-all focus:opacity-90 relative ';
    } else if (theme === 'grey') {
      // Theme: grey
      return 'bg-slate-600 font-bold leading-tight text-white rounded-md hover:bg-slate-500 transition-all focus:bg-slate-500 relative ';
    } else if (theme === 'grey-dark') {
      // Theme: grey
      return 'bg-slate-900 hover:text-white font-bold leading-tight text-slate-400 rounded-md transition-all relative ';
    } else if (theme === 'grey-light') {
      // Theme: grey-light
      return 'bg-slate-500 hover:text-white font-bold leading-tight text-white rounded-md transition-all relative ';
    } else if (theme === 'alternative-grey-light') {
      // Theme: alternative-grey-light
      return 'bg-[#363A46] hover:text-white font-bold leading-tight text-white rounded-md transition-all relative ';
    } else if (theme === 'twitter') {
      // Theme: twitter
      return 'bg-[#1D9BF0] font-bold leading-tight text-white rounded-md hover:brightness-90 transition-all focus:brightness-90 relative ';
    } else if (theme === 'x') {
      // Theme: x
      return 'bg-white font-bold leading-tight text-black rounded-md hover:bg-gray-100 transition-all focus:bg-gray-100 relative ';
    } else if (theme === 'google') {
      // Theme: goggle
      return 'flex items-center justify-center bg-white border rounded-lg shadow-md px-6 py-2 text-sm font-bold text-gray-800 [&_span]:text-gray-800 hover:bg-gray-200 focus:bg-gray-200 focus:hover:bg-gray-200 active:bg-gray-200 active:focus:bg-gray-200 transition ease-in duration-200 ';
    } else if (theme === 'facebook') {
      // Theme: facebook
      return 'flex items-center justify-center bg-blue-600 hover:bg-blue-700 border-blue-600 rounded-lg shadow-md px-6 py-2 text-sm font-bold text-white fill-white [&_span]:text-white hover:bg-blue-700 focus:bg-blue-700 focus:hover:bg-blue-700 active:bg-blue-700 active:focus:bg-blue-700 transition ease-in duration-200 ';
    } else if (theme === 'link') {
      // Theme: link
      return 'bg-none font-bold leading-tight text-blue-500 hover:text-blue-400 transition-all focus:text-blue-400 relative ';
    } else {
      // Theme: primary (default)
      return 'bg-blue-500 font-bold leading-tight text-white rounded-md hover:bg-blue-400 transition-all focus:bg-blue-400 relative ';
    }
  };

  const getBtnSizeClass = () => {
    if (size === 'sm') {
      return padding ? 'text-sm py-3 px-6 ' : 'text-sm';
    } else if (size === 'xs') {
      return padding ? 'text-sm py-2 px-4 ' : 'text-sm ';
    } else if (size === '2xs') {
      return padding ? 'text-xs py-1 px-3 ' : 'text-xs ';
    } else if (size === '3xs') {
      return padding ? 'text-2xs py-0.5 px-1.5 ' : 'text-2xs ';
    } else {
      // Size: md (default)
      return padding ? 'text-md py-4 px-8 ' : 'text-md ';
    }
  };

  const btnClass = getBtnSizeClass() + getBtnIdleClass() + getBtnDisabledClass();

  return {
    btnClass,
  };
};
