export const useMessageHandler = (actionParams, callback, reqType, reqPayloadType) => {
  const { name, args, } = actionParams;
  if (name === 'handleMessage') {
    const msgType = args[0].type;
    const msgPayload = args[0].payload;
    switch (msgType) {
      // 'Notification:Added' message type have payload with internal type, so these must be captured as special case
      case 'Notification:Added':
      case 'Activity':
        // Specific notifications
        if (
          (msgPayload?.type === reqPayloadType || reqPayloadType?.includes(msgPayload?.type))
          || reqPayloadType === 'CatchAll'
        ) {
          return callback(msgPayload);
        }
        break;
      default:
        if (reqType === msgType) {
          return callback(msgPayload);
        }
        break;
    }
  }
};
