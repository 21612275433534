import { storeToRefs } from 'pinia';

import { useAuthStore } from '@/store/auth';
import { useUiStore } from '@/store/ui';
import { useWebsocketStore } from '@/store/websocket';
import { useCryptoStore } from '@/store/crypto';
import { useDeviceStore } from '@/store/device';
import { useWalletProviderStore } from '@/store/wallet-provider';
import { useBlockchainConfigStore } from '@/store/blockchainConfig';
import { useSportsBettingStore } from '@/store/sportsBetting';

export default defineNuxtPlugin((nuxtApp) => {
  const deviceStore = useDeviceStore();
  const authStore = useAuthStore();
  const {
    onboarding,
    isUserLogged,
    authType,
  } = storeToRefs(authStore);
  const uiStore = useUiStore();
  const websocketStore = useWebsocketStore();
  const {
    ws,
  } = storeToRefs(websocketStore);
  const cryptoStore = useCryptoStore();
  const walletProviderStore = useWalletProviderStore();
  const {
    initiated,
  } = storeToRefs(walletProviderStore);
  const blockchainConfigStore = useBlockchainConfigStore();

  const sportsBettingStore = useSportsBettingStore();
  const { fetchSportsBettingProviders, } = sportsBettingStore;

  nuxtApp.hook('app:created', async() => {
    // Init stores
    deviceStore.init();
    uiStore.init();
    blockchainConfigStore.init();

    if (!initiated.value) { walletProviderStore.init(); }

    if (!onboarding.value) {
      await authStore.getUser();
      await authStore.init();
    } else if (!isUserLogged.value) {
      await authStore.getUser();
    }

    if (authType.value === 'Web3' && isUserLogged.value) {
      await authStore.initWeb3();
    }

    if (isUserLogged.value) {
      await fetchSportsBettingProviders();
    }

    // Close mobile nav
    uiStore.closeMobileNav();

    // Start websocket
    if (!ws.value) {
      websocketStore.websocketConnect();
    }

    // Start crypto store with exchange rate polling
    if (!cryptoStore.initiated) {
      cryptoStore.cryptoInit();
    }

    if (isUserLogged.value) {
      usePlatformStarter();
    }
  });
});
