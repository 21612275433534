import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime.js';
import advancedFormat from 'dayjs/plugin/advancedFormat.js';
import utc from 'dayjs/plugin/utc.js';
import timezone from 'dayjs/plugin/timezone.js';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore.js';
import isTomorrow from 'dayjs/plugin/isTomorrow.js';
import isToday from 'dayjs/plugin/isToday.js';
import updateLocale from 'dayjs/plugin/updateLocale.js';
import isoWeek from 'dayjs/plugin/isoWeek.js';

dayjs.extend(relativeTime, {
  thresholds: [
    { l: 's', r: 0.01, },
    { l: 'ss', r: 59, d: 'second', },
    { l: 'm', r: 1, },
    { l: 'mm', r: 59, d: 'minute', },
    { l: 'h', r: 1, },
    { l: 'hh', r: 24, d: 'hour', },
    { l: 'd', r: 1, },
    { l: 'dd', r: 365, d: 'day', },
    { l: 'M', r: 1, },
    { l: 'MM', r: 11, d: 'month', },
    { l: 'y', },
    { l: 'yy', r: 1, d: 'year', },
  ],
  rounding: Math.floor,
});

dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: '1 second',
    ss: '%d seconds',
    m: '1 minute',
    mm: '%d minutes',
    h: '1 hour',
    hh: '%d hours',
    d: '1 day',
    dd: '%d days',
    M: '1 month',
    MM: '%d months',
    y: '1 year',
    yy: '%d years',
  },
});

dayjs.extend(advancedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isSameOrBefore);
dayjs.extend(isTomorrow);
dayjs.extend(isToday);
dayjs.extend(isoWeek);

export default defineNuxtPlugin(() => {
  return {
    provide: {
      dayjs,
    },
  };
});
