import { defineStore } from 'pinia';
import { gte, lt, satisfies } from 'semver';
import Web3 from 'web3';
import { BigNumber } from 'bignumber.js';
import { useAuthStore } from '@/store/auth';
import { useUiStore } from '@/store/ui';
import { useDeviceStore } from '@/store/device';
import { useBankingStore } from '@/store/banking';
import { useCryptoStore } from '@/store/crypto';
import { NULL_ETHEREUM_ADDRESS } from '@/core/ethereum/nullAddress';
import { getContract, createContract } from '@/core/sweepstake/getContract';
import { SWEEPSTAKE_CONTRACT_TYPE } from '@/core/sweepstake/contractType';
import { SWEEPSTAKE_CONTRACT_FEATURE, ALL_SWEEPSTAKE_CONTRACT_FEATURES } from '@/core/sweepstake/contractFeature';
import { getVersion } from '@/core/sweepstake/getVersion';
import { getBrand } from '@/core/sweepstake/getBrand';
import { groupBy } from '@/utils/groupBy';

export const useSweepstakeStore = defineStore({
  id: 'sweepstake-store',
  state: () => {
    return {
      dateFrom: '2022-06-16T00:00:00.000Z',
      sweepstakes: [],
      lootboxes: [],
      pendingQueue: [],
      passwordsPassed: [],
      activityUpdatePayload: '',
      activityUpdateCount: 0,
      activityCountMax: 20, // controls how many activities load in the tab and the max number of activity updates
      ownedNftsForSelectedRaffle: null,
      ownedERC20ForSelectedRaffle: null,
      stakedTokenForSelectedRaffle: null,
      pollerSweepstake: null,
      pollerLootbox: null,
      dataBuffer: null,
      maxRecentAge: 30,
      sweepstakeEndingNext: null,
    };
  },
  actions: {
    async getSweepstakes(type, presentationType) {
      const nuxtApp = useNuxtApp();
      try {
        const data = await nuxtApp.$api('/sweepstake', {
          method: 'GET',
          params: {
            ...type && { type, },
            ...presentationType && { presentationType, },
            includeRecent: this.maxRecentAge,
          },
        });
        // Performance fix - only update data in UI if it's different to data already stored
        const newDataBuffer = JSON.stringify(data);
        if (newDataBuffer === this.dataBuffer) { return; }

        this.dataBuffer = newDataBuffer;
        presentationType === 'Lootbox' ? this.lootboxes = data : this.sweepstakes = data;
      } catch (err) {
        // nuxtApp.$rollbar.error('method failed', err);
        console.error(err);
      }
    },
    async getSweepstakeById(id) {
      const nuxtApp = useNuxtApp();
      try {
        const data = await nuxtApp.$api(`/sweepstake/${id}`, { method: 'GET', });
        return data;
      } catch (err) {
        nuxtApp.$rollbar.error('method failed', err);
      }
    },
    async getSweepstakeWinnersById(id) {
      const nuxtApp = useNuxtApp();
      try {
        const data = await nuxtApp.$api(`/sweepstake/${id}/winner`, { method: 'GET', });
        return data;
      } catch (err) {
        nuxtApp.$rollbar.error('method failed', err);
      }
    },
    /**
     * NB: Not currently used
     */
    async getHistory(skip, take, type, presentationType, mechanism) {
      const nuxtApp = useNuxtApp();
      try {
        return await nuxtApp.$api('/sweepstake/history', {
          method: 'GET',
          params: {
            skip,
            take,
            ...type && { type, },
            ...presentationType && { presentationType, },
            ...mechanism && { mechanism, },
          },
        });
      } catch (err) {
        nuxtApp.$rollbar.error('method failed', err);
      }
    },
    async getSweepstakesEntered(displayName, page = 1, pageSize = 20, type, presentationType = 'Competition') {
      // Temporarily if we query Lootbox, we want to return Platform items only
      if (presentationType === 'Lootbox') { type = 'Platform'; }

      const nuxtApp = useNuxtApp();
      try {
        return await nuxtApp.$api('/sweepstake/history/participated', {
          method: 'GET',
          params: {
            page,
            ...pageSize && { pageSize, },
            ...displayName && { displayName, },
            ...type && { type, },
            ...presentationType && { presentationType, },
          },
        });
      } catch (err) {
        nuxtApp.$rollbar.error('method failed', err);
      }
    },
    async getAnyUserWinHistory(displayName, page) {
      const nuxtApp = useNuxtApp();
      try {
        const data = await nuxtApp.$api('/sweepstake/history/win', {
          method: 'GET',
          params: {
            displayName,
            page,
            // entered: true,
          },
        });
        return data;
      } catch (err) {
        nuxtApp.$rollbar.error('method failed', err);
      }
    },
    async getSweepstakesEndingSoon(type, presentationType, mechanism) {
      const nuxtApp = useNuxtApp();
      try {
        let sweepstakes = await nuxtApp.$api('/sweepstake', {
          method: 'GET',
          params: {
            ...type && { type, },
            ...presentationType && { presentationType, },
            ...mechanism && { mechanism, },
          },
        });
        sweepstakes = sweepstakes.filter(e => [3,].includes(e?.state));
        sweepstakes = _SortBy(sweepstakes, 'closeTime', 'asc');
        this.sweepstakeEndingNext = sweepstakes[0] || null;

        return sweepstakes;
      } catch (err) {
        nuxtApp.$rollbar.error('method failed', err);
      }
    },
    async enterClientRaffle(sweepstake, entryPackage) {
      const uiStore = useUiStore();
      const deviceStore = useDeviceStore();
      const nuxtApp = useNuxtApp();
      const gtmLabel = sweepstake?.name;
      const gtmValue = entryPackage?.price;

      let gasLimit = 0;
      let balance = 0;

      try {
        const { wallet, address: from, } = useAuthStore();

        if (!from) {
          uiStore.openConnectModal({
            web3Only: true,
            chainType: 'Ethereum',
            message: 'This competition is on-chain, to take part you need to connect your wallet.',
          });
          return {
            sweepstake,
            res: null,
          };
        }

        if (wallet.network === 'Solana') {
          uiStore.showErrorModal = true;
          uiStore.errorModalType = 'General';
          uiStore.errorModalTitle = 'Solana Not Supported';
          uiStore.errorModalMessage = 'Sorry, Solana is not supported for on-chain competitions. Please connect using an Ethereum wallet.';
          return {
            sweepstake,
            res: null,
          };
        }

        await wallet.checkChain(sweepstake.blockchain?.network);

        nuxtApp.$gtmCustomEvent({ event: 'competition_purchase', action: 'started', label: gtmLabel, value: gtmValue, });

        const accounts = await wallet.provider.request({
          method: 'eth_accounts',
        });

        if (!accounts || !accounts.length) { nuxtApp.$rollbar.error('No accounts found'); }

        if (accounts[0].toLowerCase() !== from.toLowerCase()) { nuxtApp.$rollbar.error(`Expected ${from} to be first account but got ${accounts[0]}`); }

        const web3 = new Web3(wallet.provider);
        const version = getVersion(sweepstake);
        const contract = await getContract(web3, sweepstake);
        const externalId = sweepstake.blockchain?.externalId;
        const metadata = sweepstake.blockchain?.contract.metadata || {};
        const features = sweepstake.blockchain?.contract.features || ALL_SWEEPSTAKE_CONTRACT_FEATURES;
        const contractType = sweepstake.blockchain?.contract.type || SWEEPSTAKE_CONTRACT_TYPE.Standard;
        const network = sweepstake.blockchain?.network;

        let selectedTokenIds = [0,];
        let collectionAddress = NULL_ETHEREUM_ADDRESS;
        const nftRestrictionCount = Number(metadata.nftRestrictionCount || 1);

        balance = web3.utils.fromWei(await web3.eth.getBalance(from, 'latest'));

        if (balance.toString() === '0') { throw new Error('insufficient funds'); }

        this.requirements = sweepstake?.blockchain?.entryRequirements || null;

        // Only do the owned NFT lookup if the sweepstake has entry requirements.
        if (this.requirements) {
          if (!this.ownedNftsForSelectedRaffle) {
            const addresses = this.requirements.map(el => el.contractAddress);
            await this.checkUserWalletHasNfts(addresses, network);
          }

          const ownedNfts = this.ownedNftsForSelectedRaffle?.ownedNfts;

          if (!ownedNfts || ownedNfts.length === 0) { throw new Error('tokenId not found'); }

          // Only perform previously used NFT checks for older contracts, as this is not supported in V3.
          if (lt(version, '3.0.0')) {
            const mappedNfts = this.ownedNftsForSelectedRaffle?.ownedNfts?.map(nft => ({
              name: nft.title,
              tokenId: Web3.utils.hexToNumber(nft.id.tokenId),
              contractAddress: nft.contract?.address,
            }));

            const ownedNftGroups = groupBy(mappedNfts, 'contractAddress');

            const selectedNftGroups = Object.entries(ownedNftGroups)
            .map(([key, value,]) => ({ contractAddress: key, nfts: value, }))
            .filter(g => g.nfts.length >= nftRestrictionCount);

            let passed = false;
            const usedTokenIds = [];
            const availableTokenIds = [];

            // Loop through all of the players NFTs to check availability
            for (const group of selectedNftGroups) {
              const tokenIds = group.nfts.map(n => n.tokenId);

              if (tokenIds.length < nftRestrictionCount) {
                continue;
              }

              collectionAddress = group.contractAddress;

              // Check if the NFT has been used in the sweepstake by another player.
              for (const tokenId of tokenIds) {
                if (availableTokenIds.length >= nftRestrictionCount) { break; }

                const encoded = web3.eth.abi.encodeParameters([
                  'address',
                  'uint256',
                  'uint256',
                ], [
                  collectionAddress,
                  externalId,
                  tokenId,
                ]);

                const hash = Web3.utils.sha3(encoded);
                const usedAddress = await contract.methods.requiredNFTWallets(hash).call();

                if (usedAddress === NULL_ETHEREUM_ADDRESS) {
                  availableTokenIds.push(tokenId);
                  continue;
                }

                if (usedAddress.toUpperCase() !== from.toUpperCase()) {
                  nuxtApp.$rollbar.error(`Sweepstake Entry: NFT #${tokenId} from collection ${collectionAddress} already used by ${usedAddress}.`);
                  usedTokenIds.push(tokenId);
                  continue;
                }

                // The player is re-entering sweepstake using same tokens.
                availableTokenIds.push(tokenId);
              }

              selectedTokenIds = availableTokenIds.slice(0, nftRestrictionCount);

              // Don't bother checking other collections if we've satified the requirement.
              if (selectedTokenIds.length === nftRestrictionCount) {
                passed = true;
                break;
              }
            }

            if (!passed) { throw new Error(usedTokenIds.length >= 1 ? 'tokenId used' : 'tokenId not found'); }
          }
        }

        if (metadata.erc20Gated) {
          const tokenAddress = metadata.erc20Address;

          if (!tokenAddress) { throw new Error('ERC20 contract address not found'); }

          if (!this.ownedERC20ForSelectedRaffle) { await this.checkERC20Balance(tokenAddress); }

          const tokenBalance = this.ownedERC20ForSelectedRaffle[metadata.erc20Address];

          if (!tokenBalance || tokenBalance.isZero()) { throw new Error('ERC20 token not found'); }

          const minTokenBalance = metadata?.erc20MinBalance || 1;

          if (tokenBalance.isLessThan(minTokenBalance)) { throw new Error(`Insufficient ${metadata.erc20Name} balance`); }
        }

        if (metadata.uncxStakingGated) {
          const poolAddress = metadata.uncxStakingAddress;

          if (!poolAddress) { throw new Error('UNCX pool contract address not found'); }

          if (!this.stakedTokenForSelectedRaffle) { await this.checkUNCXStakingBalance(poolAddress); }

          const poolBalance = this.stakedTokenForSelectedRaffle[metadata.uncxStakingAddress];

          if (!poolBalance || poolBalance.isZero()) { throw new Error('UNCX pool not found'); }

          const minStakedBalance = metadata?.uncxStakingMinBalance || 1;

          if (poolBalance.isLessThan(minStakedBalance)) { throw new Error(`Insufficient ${metadata.uncxStakingTokenName} balance`); }
        }

        const transactionArgs = [];
        const packageId = gte(version, '3.0.0') ? sweepstake.entryPackages.findIndex(e => e.id === entryPackage.id) : entryPackage.id;

        switch (true) {
          case (contractType === SWEEPSTAKE_CONTRACT_TYPE.Challenge && features.includes(SWEEPSTAKE_CONTRACT_FEATURE.NFTRestriction)):
            transactionArgs.push(externalId, collectionAddress, selectedTokenIds[0]);
            break;

          case contractType === SWEEPSTAKE_CONTRACT_TYPE.Challenge:
            transactionArgs.push(externalId);
            break;

          case (satisfies(version, '>=1.3.10 <3.0.0') && features.includes(SWEEPSTAKE_CONTRACT_FEATURE.NFTRestriction) && nftRestrictionCount === 2):
            transactionArgs.push(externalId, packageId, collectionAddress, selectedTokenIds);
            break;

          case (satisfies(version, '>=1.2.0 <3.0.0') && features.includes(SWEEPSTAKE_CONTRACT_FEATURE.NFTRestriction)):
            transactionArgs.push(externalId, packageId, collectionAddress, selectedTokenIds[0]);
            break;

          case (satisfies(version, '>=1.1.0 <3.0.0') && features.includes(SWEEPSTAKE_CONTRACT_FEATURE.NFTRestriction)):
            transactionArgs.push(externalId, packageId, selectedTokenIds[0]);
            break;

          default:
            transactionArgs.push(externalId, packageId);
            break;
        }

        if (gte(version, '1.4.0')) { transactionArgs.push(getBrand(version)); }

        const transaction = contract.methods.buyEntry(...transactionArgs);

        const value = Web3.utils.toWei(entryPackage.price.toString());

        const pendingItem = {
          sweepstakeId: sweepstake.id,
          entryPackageId: entryPackage.id,
          txHash: null,
        };

        this.pendingQueue.push(pendingItem);

        if (!from) { nuxtApp.$rollbar.error('Sweepstake Entry: Ethereum address is not populated!'); }

        if (!Web3.utils.isAddress(from)) { nuxtApp.$rollbar.error(`Sweepstake Entry: Invalid address: ${from}`); }

        const gasEstimate = await transaction.estimateGas({
          from,
          value,
        });

        gasLimit = Math.round(new BigNumber(gasEstimate).multipliedBy(1.1));

        if (!deviceStore.isMobileDevice && wallet.modal?.transaction) { uiStore.showWalletConfirmModal = true; }

        const res = await this.sendTxn(
          transaction,
          {
            from: Web3.utils.toChecksumAddress(from),
            value,
            gasLimit,
          },
          pendingItem,
          gtmLabel,
          gtmValue
        );

        if (res === null) {
          nuxtApp.$gtmCustomEvent({
            event: 'competition_purchase',
            action: 'failedUserRejected',
            label: gtmLabel,
            value: gtmValue,
          });
        }

        return { res, sweepstake, };
      } catch (err) {
        nuxtApp.$rollbar.error(`Failed to add sweepstake client entry: ${err?.message}`, err);
        if (err.message?.includes('insufficient funds')) {
          uiStore.errorModalType = 'InsufficientWalletBalance';
          uiStore.errorModalData = {
            balance,
            amount: entryPackage.price,
            currencyCode: entryPackage.currencyCode,
            gasFee: gasLimit,
            network: sweepstake.blockchain?.network || 'Ethereum',
          };
          uiStore.showErrorModal = true;
          nuxtApp.$gtmCustomEvent({ event: 'competition_purchase', action: 'failedLowBalance', label: gtmLabel, value: gtmValue, });
        } else if (err.message?.includes('tokenId not found')) {
          uiStore.toastNotification = {
            type: 'info',
            title: 'Failed to enter competition',
            content: 'NFT not found.',
            closeAfter: 5000,
          };
        } else if (err.message?.includes('tokenId used')) {
          uiStore.toastNotification = {
            type: 'info',
            title: 'Failed to enter competition',
            content: 'NFT has already been used by another player.',
            closeAfter: 5000,
          };
        } else {
          nuxtApp.$rollbar.error(err);
        }
        return { res: null, sweepstake, };
      } finally {
        uiStore.showWalletConfirmModal = false;
        this.pendingQueue = this.pendingQueue.filter((item) => {
          return item.entryPackageId !== entryPackage.id;
        });
      }
    },
    async sendTxn(transaction, params, queued, gtmLabel, gtmValue) {
      const nuxtApp = useNuxtApp();
      try {
        await transaction.send(params)
        .once('transactionHash', (hash) => {
          nuxtApp.$gtmCustomEvent({ event: 'competition_purchase', action: 'txnHashReceived', label: gtmLabel, value: gtmValue, });
          queued.txHash = hash;
        });

        nuxtApp.$gtmCustomEvent({ event: 'competition_purchase', action: 'success', label: gtmLabel, value: gtmValue, });
        return true;
      } catch (err) {
        if (err.code === 4001) { return null; }
        nuxtApp.$rollbar.error('method failed', err);
      }
    },
    async enterServerRaffle(sweepstakeId, entryPackage, currency) {
      const nuxtApp = useNuxtApp();
      const bankingStore = useBankingStore();
      const uiStore = useUiStore();
      const fundsContext = bankingStore.currentContext;
      try {
        const res = await nuxtApp.$api(`/sweepstake/${sweepstakeId}`, {
          method: 'POST',
          body: {
            packageId: entryPackage.id,
            fundsContext,
            currency,
          },
        });
        await bankingStore.loadWallet();
        nuxtApp.$gtmCustomEvent({ event: 'competition_purchase', action: 'success', label: `Winbox-${sweepstakeId}`, });
        return res;
      } catch (err) {
        nuxtApp.$rollbar.error(`Failed to add sweepstake server entry: ${err?.message}`, err);
        if (err?.data.name === 'Forbidden') { uiStore.toastNotification = { type: 'info', title: 'Failed to enter competition', content: err?.data.message, closeAfter: 5000, }; }

        if (err?.data.name === 'InsufficientFunds') {
          uiStore.errorModalType = 'InsufficientWalletBalance';
          uiStore.errorModalData = {
            balance: 0,
            amount: 1,
            hideGasFeeInfo: true,
            isPlatformBalance: true,
          };
          uiStore.showErrorModal = true;
          nuxtApp.$gtmCustomEvent({ event: 'competition_purchase', action: 'failedLowBalance', label: `Winbox-${sweepstakeId}`, });
        } else if (!err.status || err.status >= 500) {
          nuxtApp.$rollbar.error(err);
        }
      }
    },
    async checkUserWalletHasNfts(sweepstakeNfts, network = 'Ethereum') {
      if (!sweepstakeNfts || sweepstakeNfts.length === 0) { return; }

      const nuxtApp = useNuxtApp();
      const authStore = useAuthStore();
      const envVars = useRuntimeConfig().public;
      const baseURL = envVars[`ALCHEMY_${network.toUpperCase()}_ENDPOINT`] || envVars.ALCHEMY_ETHEREUM_ENDPOINT;

      const address = authStore.address;
      const url = `${baseURL}/getNFTs/`;

      const params = {
        owner: address,
        'contractAddresses[]': sweepstakeNfts,
      };

      try {
        const data = await $fetch(url, { method: 'GET', params, });
        this.ownedNftsForSelectedRaffle = data;
      } catch (err) {
        nuxtApp.$rollbar.error('method failed', err);
        this.ownedNftsForSelectedRaffle = { forceAllow: true, };
        nuxtApp.$rollbar.warning('Alchemy endpoint call failed. Force-allowed access to competition with NFT requirement');
      }
    },
    async checkERC20Balance(tokenAddress) {
      if (!tokenAddress) { return; }
      const cryptoStore = useCryptoStore();
      const authStore = useAuthStore();
      const { wallet, } = authStore;

      if (wallet?.network === 'Solana') { return; }

      const tokenBalance = await cryptoStore.getTokenBalance(tokenAddress);
      this.ownedERC20ForSelectedRaffle = {
        [`${tokenAddress}`]: new BigNumber(tokenBalance || 0),
      };
    },
    async checkUNCXStakingBalance(poolAddress) {
      if (!poolAddress) { return; }

      const authStore = useAuthStore();
      const { wallet, address, } = authStore;

      if (!wallet || wallet.network === 'Solana') {
        return;
      }

      const ABI = [{
        inputs: [{
          internalType: 'address',
          name: '_user',
          type: 'address',
        },],
        name: 'getUserInfo',
        outputs: [
          {
            components: [
              {
                internalType: 'address',
                name: 'user_address',
                type: 'address',
              },
              {
                internalType: 'uint256',
                name: 'shares',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'tokens_staking',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'share_weight',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'unlock_date',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'boost_additional_time',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'time_boost_percentage',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'uncl_boost_percentage',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'subscriptions_length',
                type: 'uint256',
              },
              {
                internalType: 'address',
                name: 'accepted_stake_fee_token',
                type: 'address',
              },
              {
                internalType: 'uint256',
                name: 'accepted_stake_fee_amount',
                type: 'uint256',
              },
            ],
            internalType: 'struct IStakePool.UserInfo',
            name: '',
            type: 'tuple',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },];

      let stakedBalance = 0;

      try {
        const web3 = new Web3(wallet.provider);
        const contract = new web3.eth.Contract(ABI, poolAddress);
        const userInfo = await contract.methods.getUserInfo(address).call();
        stakedBalance = userInfo.tokens_staking;
        this.stakedTokenForSelectedRaffle = {
          [`${poolAddress}`]: new BigNumber(stakedBalance || 0).shiftedBy(-18),
        };
      } catch (err) {
      }
    },
    checkRequirementsPass(requirements, itemCount = 1) {
      if (!requirements) { return; }
      const passes = [];
      this.requirementsNftCount = this.raffle;
      for (const requirement of requirements) {
        if (requirement.type === 'NFT') {
          let nftsOwned = 0;
          if (this.ownedNftsForSelectedRaffle?.ownedNfts?.length) {
            for (const nft of this.ownedNftsForSelectedRaffle.ownedNfts) {
              if (nft?.contract?.address.toLowerCase() === requirement.contractAddress.toLowerCase()) {
                nftsOwned = nftsOwned + 1;
              }
            }
          }
          if (this.ownedNftsForSelectedRaffle?.forceAllow === true) { nftsOwned++; }
          nftsOwned >= itemCount ? passes.push(true) : passes.push(false);
        }
      }
      const finalPass = !!passes.includes(true);
      return finalPass;
    },
    checkERC20RequirementsPass(tokenAddress, minTokenBalance = 1) {
      if (!tokenAddress) { return false; }

      if (!this.ownedERC20ForSelectedRaffle) { return false; }

      const tokenBalance = this.ownedERC20ForSelectedRaffle[tokenAddress];

      if (!tokenBalance || tokenBalance.isZero()) { return false; }

      if (tokenBalance.isLessThan(minTokenBalance)) { return false; }

      return true;
    },
    checkUNCXStakingPass(poolAddress, minTokenBalance = 1) {
      if (!poolAddress) { return false; }

      if (!this.stakedTokenForSelectedRaffle) { return false; }

      const stakedBalance = this.stakedTokenForSelectedRaffle[poolAddress];

      if (!stakedBalance || stakedBalance.isZero()) { return false; }

      if (stakedBalance.isLessThan(minTokenBalance)) { return false; }

      return true;
    },
    logoutReset() {
      this.userHistoryPartial = [];
      this.pendingQueue = [];
      this.ownedNftsForSelectedRaffle = null;
      this.ownedERC20ForSelectedRaffle = null;
      this.stakedTokenForSelectedRaffle = null;
    },
    async claimRefund(raffle) {
      const nuxtApp = useNuxtApp();
      try {
        const { wallet, address: from, } = useAuthStore();

        if (!wallet.connected) {
          alert('Wallet not connected');
          return;
        }

        await wallet.checkChain(raffle.blockchain?.network);

        const { address, } = raffle.contract; // version, type, prizeTypes, metadata
        const features = raffle.contract.features || ALL_SWEEPSTAKE_CONTRACT_FEATURES;

        if (!features.includes(SWEEPSTAKE_CONTRACT_FEATURE.RefundClaim)) { return false; }

        const web3 = new Web3(wallet.provider);
        const contract = await createContract(web3, address, raffle.contract.abiId);
        const transaction = contract.methods.claimRefund(raffle.externalId);

        const gasEstimate = await transaction.estimateGas({
          from,
        });

        const gasLimit = Math.round(gasEstimate * 1.1);

        if (!from) { nuxtApp.$rollbar.error('Sweepstake Refund: Ethereum address is not populated!'); }

        if (!web3.utils.isAddress(from)) { nuxtApp.$rollbar.error(`Sweepstake Entry: Invalid address: ${from}`); }

        const receipt = await transaction.send({
          from: web3.utils.toChecksumAddress(from),
          gasLimit,
        });

        return !!receipt;
      } catch (err) {
        nuxtApp.$rollbar.error(err);
        throw err;
      }
    },
    async interact(sweepstakeId, action) {
      const nuxtApp = useNuxtApp();
      try {
        return await nuxtApp.$api(`/sweepstake/${sweepstakeId}/interact/${action}`, { method: 'POST', });
      } catch (err) {
        nuxtApp.$rollbar.error(err);
      }
    },
    async getSweepstakeEndingSoon(presentationType) {
      const nuxtApp = useNuxtApp();
      try {
        let data = await nuxtApp.$api('/sweepstake', {
          method: 'GET',
          params: {
            ...presentationType && { presentationType, },
          },
        });
        if (!data || !data.length) { return null; }
        data = _SortBy(data, ['closeTime',]);
        return data[0];
      } catch (err) {
        nuxtApp.$rollbar.error('method failed', err);
      }
    },
    async toggleReminder(sweepstakeId, hasReminder) {
      const nuxtApp = useNuxtApp();
      const uiStore = useUiStore();
      try {
        await nuxtApp.$api(`/sweepstake/${sweepstakeId}/reminder`, { method: 'PUT', });

        if (!hasReminder && !uiStore.reminderModalHide) {
          uiStore.infoModalType = 'CompetitionReminder';
          uiStore.showInfoModal = true;
        }
      } catch (err) {
        nuxtApp.$rollbar.error('method failed', err);
      }
    },
    async getDisplayCategories() {
      // Get categories from Sanity CMS. Use fallback data if fails.
      const nuxtApp = useNuxtApp();
      let query = `*[_type == "competition-categories" && homepageDisplay && loggedInOnly != true] | order(orderRank) {
        title,
        categoryFilter,
        displayMode,
        carouselInitialState,
        hidePrice,
        isFilterAppBased,
        order,
        orderRank,
        excludeFromClosingToday,
        homepageDisplay,
        loggedInOnly
      }`;

      const authStore = useAuthStore();

      if (authStore.isUserLogged) {
        query = `*[_type == "competition-categories" && homepageDisplay] | order(orderRank) {
          title,
          categoryFilter,
          displayMode,
          carouselInitialState,
          hidePrice,
          isFilterAppBased,
          order,
          orderRank,
          excludeFromClosingToday,
          homepageDisplay,
          loggedInOnly
        }`;
      }

      try {
        const data = await nuxtApp.$sanity.fetch(query);
        return data;
      } catch (err) {
        nuxtApp.$rollbar.error('SANITY ERR failed:', err);
        try {
          const fallback = await $fetch('/data/displayCategoriesFallback.json', { method: 'GET', });
          return fallback;
        } catch (err) {
          nuxtApp.$rollbar.error('FALLBACK ERR failed:', err);
        }
      }
    },
    async getFilterCategories() {
      const nuxtApp = useNuxtApp();
      const query = '*[_type == "filter-categories"] ';
      try {
        const data = await nuxtApp.$sanity.fetch(query);
        return data;
      } catch (err) {
        nuxtApp.$rollbar.error('SANITY ERR failed:', err);
      }
    },
    resetSweepstakesData() {
      this.sweepstakes = [];
      this.dataBuffer = null;
    },
    async eligibilitiesEvaluate(sweepstakeId) {
      if (!sweepstakeId) { return; }
      const nuxtApp = useNuxtApp();
      try {
        const eligibilitiesEvaluation = await nuxtApp.$api(`/sweepstake/${sweepstakeId}/eligibility/evaluate`, {
          method: 'POST',
        });
        return eligibilitiesEvaluation;
      } catch (err) {
        nuxtApp.$rollbar.error(`Failed to evaluate sweepstake eligibilities: ${err?.message}`, err);
      }
    },
  },
});
