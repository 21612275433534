import { storeToRefs } from 'pinia';

import { useUserStore } from '@/store/user';
import { useAuthStore } from '@/store/auth';
import { getConfig } from '@/utils/getConfig';

export function useIntercom() {
  const { $intercom, $dayjs, } = useNuxtApp();
  const authStore = useAuthStore();
  const userStore = useUserStore();

  const { isUserLogged, } = storeToRefs(authStore);
  const { userData, userProfile, } = storeToRefs(userStore);

  const isUserReady = computed(() => !!(isUserLogged.value && userData.value));
  const hasOverrideTag = computed(() => !!userData.value?.tags?.includes('Intercom:Show'));

  const intercomEnabled = computed(() => getConfig('INTERCOM_ENABLED') || hasOverrideTag.value);

  const unreadCount = ref(0);

  async function initIntercom(params) {
    if (!isUserLogged.value) {
      return;
    }

    if (!intercomEnabled.value) {
      return;
    }

    // TODO: Refactor userStore to clear data at logout, then reutrn the if statement
    // this is a temporary fix to always force get profile, we have a wider issue with the store as it is.
    // it doesn't clear profile data well after logout, meaning previous users
    // profile data is still in the state when a new user logs in.
    await userStore.getUserProfile();

    const userIdentifier = userData.value?.id;
    const createTimeUnix = $dayjs(userData.value?.createTime).unix();

    $intercom.update({
      ...params,
      name: userData.value?.displayName,
      user_id: userIdentifier,
      email: userProfile.value?.emailVerified ? userProfile.value.email : null,
      created_at: createTimeUnix,
      avatar: userData.value?.avatarUrl
        ? {
          type: 'avatar',
          image_url: userData.value.avatarUrl,
        }
        : null,
    });
    $intercom.call('onUnreadCountChange', (val) => {
      unreadCount.value = val;
    });
  }

  function showIntercom() {
    if (!intercomEnabled.value) {
      return;
    }
    $intercom.show();
  }

  function hideIntercom() {
    if (!intercomEnabled.value) {
      return;
    }
    $intercom.hide();
  }

  watch(isUserReady, (newVal, oldVal) => {
    if (newVal && !oldVal) {
      initIntercom();
    } else if (!newVal && oldVal) {
      $intercom.hide();
      $intercom.shutdown();
    }
  }, { immediate: true, });

  return {
    initIntercom,
    showIntercom,
    hideIntercom,
    unreadCount,
    intercomEnabled
  };
}
