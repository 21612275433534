import { storeToRefs } from 'pinia';

import { useUiStore } from '@/store/ui';
import { useDeviceStore } from '@/store/device';
import { useVersionStore } from '@/store/version';

export default defineNuxtPlugin((nuxtApp) => {
  const uiStore = useUiStore();
  const { showPageLoader, allowModalsOnPageLoad, } = storeToRefs(uiStore);
  const deviceStore = useDeviceStore();
  const versionStore = useVersionStore();
  const route = useRoute();

  nuxtApp.hook('page:start', () => {
    showPageLoader.value = true;
  });

  nuxtApp.hook('page:finish', () => {
    showPageLoader.value = false;

    // Close all modals
    if (!allowModalsOnPageLoad.value) {
      uiStore.closeModals();
    }

    // Use hash to display UI elements (ie. video modal)
    if (route.hash) {
      uiStore.hashAction(route.hash);
    }

    // in case of error on ui init / really slow connection
    if (!uiStore.storeInitialized) {
      setTimeout(() => {
        uiStore.storeInitialized = true;
      }, 5000);
    }
  });

  nuxtApp.hook('app:mounted', () => {
    versionStore.init();

    if (deviceStore.browser === 'Safari 14') {
      uiStore.uiSettings.videoAutoplay = true;
    }
  });
});
