<template>
  <NuxtLink
    :to="to"
    :disabled="disabled"
    :target="target"
    :class="btnClass"
  >
    <slot/>
  </NuxtLink>
</template>

<script setup>
import { useButtonStyle } from '~/composables/useButtonStyle';

const props = defineProps({
  to: {
    type: [String, Object,],
    default: null,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  target: {
    type: String,
    default: null,
  },
  theme: {
    type: String,
    default: null,
  },
  size: {
    type: String,
    default: null,
  },
  padding: {
    type: Boolean,
    default: true,
  },
});

const btnClass = computed(() => {
  const classValue = useButtonStyle(props.theme, props.size, null, props.padding);
  return classValue.btnClass;
});
</script>
