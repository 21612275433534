export default defineNuxtPlugin((nuxtApp) => {
  const isActive = nuxtApp.$config.public.WYRE_ACTIVE;
  const widgetFetch = $fetch.create({
    baseURL: nuxtApp.$config.public.WYRE_API_HOST,
    onRequest({ options, }) {
      options.headers = new Headers({
        Authorization: `Bearer ${nuxtApp.$config.public.WYRE_API_TOKEN}`,
      });
    },
    onResponseError({ request, response, }) {
      nuxtApp.$rollbar.error('[fetch response]', request, response.status, response.body);
    },
  });

  return {
    provide: {
      wyre: async(initialAmount, fromCurrency, walletAddress, redirectUrl) => {
        if (!isActive) {
          return;
        }

        try {
          const res = await widgetFetch('/v3/orders/reserve', {
            method: 'POST',
            body: {
              lockFields: ['destCurrency', 'dest',],
              amount: initialAmount,
              sourceCurrency: fromCurrency,
              destCurrency: 'ETH',
              dest: `ethereum:${walletAddress}`,
              referrerAccountId: nuxtApp.$config.public.WYRE_REFERRER_ID,
              redirectUrl,
            },
          });

          return res.url;
        } catch (err) {
          nuxtApp.$rollbar.error('wyre failed:', err);
        }
      },
    },
  };
});
