import sanityClient from '@sanity/client';
import blocksToHtml from '@sanity/block-content-to-html';
import imageUrlBuilder from '@sanity/image-url';

export default defineNuxtPlugin((nuxtApp) => {
  const sanity = sanityClient({
    // Find your project ID and dataset in `sanity.json` in your studio project
    projectId: 'edcyyirq',
    // dataset: process.env.NODE_ENV === 'development' ? 'development' : 'production',
    dataset: nuxtApp.$config.public.CMS_DATASET || 'production',
    // useCdn: process.env.NODE_ENV === 'production',
    useCdn: true,
    // useCdn == true gives fast, cheap responses using a globally distributed cache.
    // Set this to false if your application require the freshest possible
    // data always (potentially slightly slower and a bit more expensive).
    apiVersion: '2024-07-01', // use a UTC date string
  });

  return {
    provide: {
      sanity,
      sanityBlocksToHtml: (blocks, serializers = null) => {
        return blocksToHtml({
          dataset: sanity.clientConfig.dataset,
          projectId: sanity.clientConfig.projectId,
          serializers,
          blocks,
        });
      },
      sanityImgUrl: (source) => {
        if (source) {
          const builder = imageUrlBuilder(sanity);
          return builder.image(source);
        }
      },
    },
  };
});
