import Rollbar from 'rollbar';

export default defineNuxtPlugin((nuxtApp) => {
  const rollbar = new Rollbar({
    accessToken: '74be7873a7c94c20b85dcc2586c27989',
    payload: {
      environment: nuxtApp.$config.public.ENV_NAME,
      client: {
        javascript: {
          captureUncaught: true,
          captureUnhandledRejections: true,
          source_map_enabled: true,
          guess_uncaught_frames: true,
          code_version: nuxtApp.$config.public.VERSION,
        },
      },
    },
  });

  if (nuxtApp.$config.public.ENV_NAME === 'development' || location?.hostname === 'localhost' || location?.port !== '') {
    rollbar.configure({
      enabled: false,
    });
  }

  nuxtApp.vueApp.use(rollbar);

  return {
    provide: {
      rollbar,
    },
  };
});
