import VueTippy from 'vue-tippy';

// Props and configs: https://github.com/KABBOUCHI/vue-tippy
// Custom css added in assets/css/extras/tippy.css
export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(VueTippy, {
    defaultProps: {
      placement: 'top',
      allowHTML: true,
    },
  });
});
